import { fetchJSON } from "./utils";

async function getCourses() {
  const path = '/course';
  return await fetchJSON(path, 'GET', {});
}

async function getCourse(id) {
  const path = `/course/${id}`;
  return await fetchJSON(path, 'GET', {});
}

async function getCourseContent(id, language) {
  const path = `/course/${id}/content?language=${language}`;
  return await fetchJSON(path, 'GET', {});
}

async function upsertCourse({ id, name, description, languages, builder_id, knowledges: rawKnowledges, responses: rawResponses }) {  
  const responses = rawResponses.map(r => ({ input_id: r.input_id, value: r.value }))
  const knowledges = rawKnowledges.map(k => k.id);
  
  if (id) {
    const path = `/course/${id}`;
    const body = { name, description, languages };
    await fetchJSON(path, 'PATCH', { body: JSON.stringify(body) });
    return id;
  }

  const path = '/course';
  const body = { name, description, languages, responses, knowledges, builder_id };
  const res = await fetchJSON(path, 'POST', { body: JSON.stringify(body) });
  return res.data;
}

async function updateCourseTemplate({ id, modules }) {
  const path = `/course/${id}/content`;
  const body = {
    modules: modules.map(m => ({
      id: m.id,
      name: m.name,
      lessons: m.lessons.map(l => ({
        id: l.id,
        name: l.name,
        has_quiz: l.data.has_quiz,
        has_activity: l.data.has_activity,
      }))
    }))
  }

  return await fetchJSON(path, 'PATCH', { body: JSON.stringify(body) });
}

async function regenerateCourse({ id }) {
  const path = `/course/${id}/regenerate`;
  return await fetchJSON(path, 'POST', {});
}

async function cancelCourse(id) {
  const path = `/course/${id}/cancel`;
  return await fetchJSON(path, 'POST', {});
}

async function publishCourse(id) {
  const path = `/course/${id}/publish`;
  return await fetchJSON(path, 'POST', {});
}

async function deleteCourse(id) {
  const path = `/course/${id}`;
  return await fetchJSON(path, 'DELETE', {});
}

export {
  getCourse,
  getCourses,
  getCourseContent,
  upsertCourse,
  updateCourseTemplate,
  regenerateCourse,
  cancelCourse,
  publishCourse,
  deleteCourse
}
