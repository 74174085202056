const baseUrl = process.env.REACT_APP_API_BASE_URL;

const courseStatus = {
  empty: 1,
  template: 2,
  content: 3,
};

const courseRunStatus = {
  pending: 1,
  inProgress: 2,
  processed: 3,
  cancelled: 4,
  failed: 5,
};

class APIError extends Error {
  constructor(message, status, data) {
    super(message);
    this.name = "APIError";
    this.status = status;
    this.data = data;
  }
}

async function fetchJSON(path, method, args) {
  const token = localStorage.getItem("token");
  const res = await fetch(`${baseUrl}${path}`, {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    ...args
  });
  const data = await res.json();

  if (!res.ok) {
    throw new APIError(res.statusText, res.status, data);
  }

  return data;
}

async function fetchForm(path, method, args) {
  const token = localStorage.getItem("token");
  const res = await fetch(`${baseUrl}${path}`, {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    ...args
  });
  const data = await res.json();

  if (!res.ok) {
    throw new APIError(res.statusText, res.status, data);
  }

  return data;
}

export {
  courseStatus,
  courseRunStatus,
  fetchJSON,
  fetchForm,
}
