import { fetchJSON } from "./utils";

async function getBuilders() {
  const path = '/builder';
  return await fetchJSON(path, 'GET', {});
}

async function getBuilder(id) {
  const path = `/builder/${id}`;
  return await fetchJSON(path, 'GET', {});
}

async function upsertBuilder({ id, name, is_default, inputs }) {  
  if (id) {
    const path = `/builder/${id}`;
    const body = { name, is_default, inputs: inputs.map(i => i.id) };
    await fetchJSON(path, 'PATCH', { body: JSON.stringify(body) });
    return id;
  }

  const path = '/builder';
  const body = { name };
  const res = await fetchJSON(path, 'POST', { body: JSON.stringify(body) });
  return res.data;
}

async function deleteBuilder(id) {
  const path = `/builder/${id}`;
  return await fetchJSON(path, 'DELETE', {});
}

export {
  getBuilder,
  getBuilders,
  upsertBuilder,
  deleteBuilder
}
