import { Flex, Text, Button, Box, Heading, Skeleton, Separator, Badge, SegmentedControl, Spinner, Callout } from '@radix-ui/themes';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams }  from 'react-router-dom';
import { useState, useEffect } from 'react';

import { SideList } from '../components/SideList';
import { getCourses, getCourse } from '../api/courses';
import { courseRunStatus, courseStatus } from '../api/utils';
import { CourseForm } from '../components/CourseForm';
import { CourseContent } from '../components/CourseContent';
import { InfoCircledIcon } from '@radix-ui/react-icons';

const courseStatusBadge = [
  {color: 'cyan', label: 'New'},
  {color: 'cyan', label: 'Template'},
  {color: 'cyan', label: 'Content'},
]

const courseRunStatusBadge = [
  {color: 'mint', label: 'Generating'},
  {color: 'jade', label: 'Generating'},
  {color: 'green', label: 'Processed'},
  {color: 'amber', label: 'Cancelled'},
  {color: 'red', label: 'Failed'},
]

function Courses() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const courseId = searchParams.get('course_id');

  const courses = useQuery({ queryKey: ['/course'], queryFn: getCourses })
  const course = useQuery({ queryKey: ['/course/id'], queryFn: () => courseId && courseId !== 'new' ? getCourse(courseId) : null })

  const [view, setView] = useState('form');

  const [courseForm, setCourseForm] = useState({
    id: undefined,
    name: '',
    description: '',
    languages: [],
    builder_id: undefined,
    knowledges: [],
    responses: [],
  });

  const [contentForm, setContentForm] = useState({
    id: undefined,
    modules: [],
  });

  const { refetch: courseRefetch } = course;
  useEffect(() => {
    courseRefetch();
    setView('form');
  }, [courseRefetch, courseId]);

  const { error: courseError } = course;
  useEffect(() => {
    if (courseError?.status === 404) {
      navigate({search: ''});
    }
  }, [courseError, courseId, navigate]);

  const isProcessing = () => courseId !== 'new' && [courseRunStatus.pending, courseRunStatus.inProgress].includes(course.data.data.course.run_status);

  return (
    <>
      <Flex>
        <SideList
          title="Courses"
          isPending={courses.isPending}
          error={courses.error}
          onRetry={courses.refetch}
          data={courses.data?.data}
          newTitle="New course"
          param="course_id" />

        <Box flexGrow="1">
          <Flex direction="column" gap="4" className="px-6 md:px-10 lg:px-16 py-10 lg:py-20 max-w-[858px] mx-auto">
            {!courseId && !course.isPending && (
              <Flex direction="column" gap="2">
                <Heading as="h1" size="8">No course</Heading>
                <Text color="gray">Select a course from the left or create a new one</Text>
              </Flex>
            )}

            {courseId === 'new' && (
              <Flex direction="column">
                <Heading as="h1" size="8">New course</Heading>
              </Flex>
            )}

            {courseId !== 'new' && course.isPending && (
              <Flex direction="column" gap="2">
                <Skeleton height="40px" width="200px" />
                <Skeleton height="24px" width="250px" />
              </Flex>
            )}

            {courseId !== 'new' && course.error && (
              <Flex direction="column" align="start" gap="2" py="2">
                <Text>Something went wrong 😭</Text>
                <Button variant="soft" onClick={course.refetch}>Retry</Button>
              </Flex>
            )}

            {courseId !== 'new' && course.data && (
              <Flex direction="column" gap="2">
                <Heading as="h1" size="8">{course.data.data.course.name}</Heading>

                <Flex gap="2" align="center">
                  <Text color="gray">ID: {course.data.data.course.id}</Text>
                  <Separator orientation="vertical" size="1" color="gray" />
                  <Text color="gray">Created: {new Date(course.data.data.course.created).toDateString()}</Text>
                  <Separator className="max-sm:hidden" orientation="vertical" size="1" color="gray" />
                  <Badge color={courseStatusBadge[course.data.data.course.status-1].color} className="max-sm:hidden">
                    {courseStatusBadge[course.data.data.course.status-1].label}
                  </Badge>
                  <Badge color={courseRunStatusBadge[course.data.data.course.run_status-1].color} className="max-sm:hidden">
                    {courseRunStatusBadge[course.data.data.course.run_status-1].label}
                    {isProcessing() && <Spinner size="1" />}
                  </Badge>
                </Flex>

                <Flex className="md:hidden" gap="1">
                  <Badge color={courseStatusBadge[course.data.data.course.status-1].color}>
                    {courseStatusBadge[course.data.data.course.status-1].label}
                  </Badge>
                  <Badge color={courseRunStatusBadge[course.data.data.course.run_status-1].color}>
                    {courseRunStatusBadge[course.data.data.course.run_status-1].label}
                    {isProcessing() && <Spinner size="1" />}
                  </Badge>
                </Flex>

                {course.data.data.course.run_status === courseRunStatus.failed && course.data.data.course.last_error && (
                  <Box maxWidth="450px">
                    <Callout.Root color="red">
                      <Callout.Icon>
                        <InfoCircledIcon />
                      </Callout.Icon>
                      <Callout.Text size="1">
                        {course.data.data.course.last_error}
                      </Callout.Text>
                    </Callout.Root>
                  </Box>
                )}

                {course.data.data.course.run_status === courseRunStatus.processed && course.data.data.course.status === courseStatus.template && (
                  <Box maxWidth="450px">
                    <Callout.Root color="green">
                      <Callout.Icon>
                        <InfoCircledIcon />
                      </Callout.Icon>
                      <Callout.Text size="1">
                        First template was generated, please check and make any adjustments as needed (remember to click "Update template").
                        Once finished, click "Continue" to generate the course contents.
                      </Callout.Text>
                    </Callout.Root>
                  </Box>
                )}

                <Box>
                  <SegmentedControl.Root value={view} onValueChange={setView}>
                    <SegmentedControl.Item value="form">📝 Form</SegmentedControl.Item>
                    <SegmentedControl.Item value="content">🪄 Content</SegmentedControl.Item>
                  </SegmentedControl.Root>
                </Box>
              </Flex>
            )}

            {courseId && view === 'form' && (
              <CourseForm
                isPending={course.isPending}
                error={course.error}
                data={course.data}
                courseForm={courseForm}
                setCourseForm={setCourseForm}
              />
            )}

            {courseId !== 'new' && view === 'content' && (
              <CourseContent
                course={course.data}
                contentForm={contentForm}
                setContentForm={setContentForm}
              />
            )}
          </Flex>
        </Box>
      </Flex>

      <Box height="49px" />
    </>
  );
}

export {
  Courses
};
