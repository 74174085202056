import { Flex, Text, Button, Box, TextField, Heading, Separator } from '@radix-ui/themes';
import { Navigate, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { loginAdmin } from '../api/users';

function Login() {
  const navigate = useNavigate();

  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
  });

  const [formErrors, setFormErrors] = useState({})

  const loginMutation = useMutation({
    mutationFn: loginAdmin,
    onSuccess: (token) => {
      toast.success('Login success! 🎉');
      localStorage.setItem("token", token);
      navigate("/");
    },
    onError: (error) => {
      toast.error(error.data?.message ?? 'Something went wrong 🥲');
      console.error(error);
    }
  });

  const { error: loginMutationError } = loginMutation;
  useEffect(() => {
    setFormErrors(loginMutationError?.data ?? {})
  }, [loginMutationError]);

  const login = () => {
    loginMutation.reset();
    loginMutation.mutate(loginForm);
  }

  const token = localStorage.getItem("token");
  if (token) {
    return <Navigate to="/" />
  }

  return (
    <Flex direction="column" align="center" py="9" gap="5">
      <Flex gap="2" align="center">
        <Heading as="h1" size="4">Wisdom</Heading>
        <Separator orientation="vertical" size="1" />
        <Heading as="h6" size="1">La Teacher</Heading>
      </Flex>

      <form>
        <Flex direction="column" gap="5" align="stretch" width="300px">
          <Box>
            <Text as="label" size="2" weight="bold">
              <Box pb="1">Email</Box>
              <TextField.Root
                name="email"
                size="2"
                placeholder="e.g. me@gmail.com"
                type="email"
                value={loginForm.email}
                onChange={(e) =>setLoginForm(prev => ({ ...prev, email: e.target.value }))}
                required
              />
              {formErrors.email && (
                <Text color="red" size="1" weight="regular">{formErrors.email}</Text>
              )}
            </Text>
          </Box>

          <Box>
            <Text as="label" size="2" weight="bold">
              <Box pb="1">Password</Box>
              <TextField.Root
                name="password"
                size="2"
                placeholder="e.g. mypassword"
                type="password"
                value={loginForm.password}
                onChange={(e) =>setLoginForm(prev => ({ ...prev, password: e.target.value }))}
                required
              />
              {formErrors.password && (
                <Text color="red" size="1" weight="regular">{formErrors.password}</Text>
              )}
            </Text>
          </Box>

          <Flex justify="end">
            <Button type="button" variant="solid" color="purple" onClick={login}>
              Login
            </Button>
          </Flex>
        </Flex>
      </form>
    </Flex>
  );
}

export {
  Login
}