import { Card, DropdownMenu, Flex, Text, Button, Box, Heading, TextField, IconButton, Skeleton, Popover, Separator, AlertDialog, Switch } from '@radix-ui/themes';
import { PlusIcon, ReloadIcon } from '@radix-ui/react-icons';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { upsertBuilder, deleteBuilder } from '../api/builders';
import { getInputs } from '../api/inputs';
import toast from 'react-hot-toast';

const inputDataTypes = ['Text', 'True/False', 'Number', 'Instruction'];

function BuilderForm({ isPending: builderIsPending, error: builderError, data: builderData }) {
  const { search } = useLocation();
  const searchURL = (id) => {
    const params = new URLSearchParams(search);
    params.set('builder_id', id);
    return params.toString();
  }

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const builderId = searchParams.get('builder_id');

  const [builderForm, setBuilderForm] = useState({
    id: undefined,
    name: '',
    is_default: false,
    inputs: []
  });

  const [formErrors, setFormErrors] = useState({})

  const inputs = useQuery({ queryKey: ['/input'], queryFn: getInputs })
  
  const queryClient = useQueryClient();

  const builderMutation = useMutation({
    mutationFn: upsertBuilder,
    onSuccess: (id) => {
      queryClient.invalidateQueries({ queryKey: ['/builder'] });
      queryClient.invalidateQueries({ queryKey: ['/builder/id'] });
      toast.success('Success! 🎉')

      if (id) {
        navigate({search: searchURL(id)});
      }
    },
    onError: (error) => {
      toast.error(error.data?.message ?? 'Something went wrong 🥲');
      console.error(error);
    }
  });

  const deleteMutation = useMutation({
    mutationFn: deleteBuilder,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/builder'] });
      toast.success('Builder deleted! 🫠')

      navigate({search: searchURL('')});
    },
    onError: (error) => {
      toast.error(error.data?.message ?? 'Something went wrong 🥲');
      console.error(error);
    }
  })

  const resetForm = (id, data) => {
    if (id === 'new' || !data) {
      setBuilderForm({
        id: undefined,
        name: '',
        is_default: false,
        inputs: []
      });
    } else if (data) {
      setBuilderForm({
        id: data.data.builder.id,
        name: data.data.builder.name,
        is_default: data.data.builder.is_default,
        inputs: data.data.inputs,
      });
    }
  }

  const { reset: builderReset } = builderMutation;

  useEffect(() => {
    builderReset();
    resetForm(builderId, builderData);
  }, [builderId, builderData, builderReset])

  useEffect(() => {
    builderReset();
  }, [builderForm, builderReset])

  const { error: builderMutationError } = builderMutation;
  useEffect(() => {
    setFormErrors(builderMutationError?.data ?? {})
  }, [builderMutationError]);

  const discardChanges = () => {
    resetForm(builderId, builderData);
  }

  const saveChanges = () => {
    builderMutation.reset();
    builderMutation.mutate(builderForm);
  }

  const dangerDelete = () => {
    deleteMutation.reset()
    deleteMutation.mutate(builderId);
  }
  
  const addInput = (input) => {
    setBuilderForm(prev => ({
      ...prev,
      inputs: [...prev.inputs, input],
    }))
  }
  
  const deleteInput = (index) => {
    const inputs = [...builderForm.inputs]
    inputs.splice(index, 1);

    setBuilderForm(prev => ({
      ...prev,
      inputs,
    }));
  }

  return (
    <>
      {builderIsPending && (
        <Flex direction="column" gap="5">
          <Skeleton height="56px" width="300px" />
          {builderId !== 'new' && (
            <Flex direction="column" gap="2">
              <Skeleton height="100px" width="300px" />
              <Skeleton height="76px" width="300px" />
              <Skeleton height="32px" width="110px" />
            </Flex>
          )}
        </Flex>
      )}

      {!builderError && !builderIsPending && (
        <form>
          <Flex direction="column" gap="5">
            <Box maxWidth="300px">
              <Text as="label" size="2" weight="bold">
                <Box pb="1">Name</Box>
                <TextField.Root
                  name="name"
                  size="2"
                  placeholder="e.g. builder-v3"
                  value={builderForm.name}
                  onChange={(e) =>setBuilderForm(prev => ({ ...prev, name: e.target.value }))}
                  required
                />
                {formErrors.name && (
                  <Text color="red" size="1" weight="regular">{formErrors.name}</Text>
                )}
              </Text>
            </Box>

            <Box>
              <Text as="label" size="2" weight="bold">
                <Box pb="1">Is the default builder?</Box>
                <Switch
                  size="2"
                  color="green"
                  name="is_default"
                  checked={builderForm.is_default}
                  onCheckedChange={value => setBuilderForm(prev => ({ ...prev, is_default: value }))}
                  required
                />
                {formErrors.is_default && (
                  <Text color="red" size="1" weight="regular">{formErrors.is_default}</Text>
                )}
              </Text>
            </Box>

            {builderForm.id && (
              <Flex direction="column" gap="2" maxWidth="300px">
                <Heading as="h6" size="2">Inputs</Heading>

                <Flex direction="column" gap="2">
                  {builderForm.inputs.length === 0 && (
                    <Card>
                      <Text as="div" size="2" weight="bold">No inputs here 🥺</Text>
                      <Text as="div" color="gray" size="2">
                        Add one with the button below
                      </Text>
                    </Card>
                  )}

                  {builderForm.inputs.length > 0 && builderForm.inputs.map((input, index) => (
                    <Card key={`builder.${builderId}.input.${input.id}`}>
                      <Flex justify="between">
                        <Flex direction="column" gap="1">
                          <Text as="div" size="2" weight="bold">{input.name}</Text>
                          <Text as="div" color="gray" size="2">{inputDataTypes[input.data_type - 1]}</Text>
                        </Flex>
                        
                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger>
                            <IconButton size="2" radius="small" variant="ghost">
                              <DropdownMenu.TriggerIcon />
                            </IconButton>
                          </DropdownMenu.Trigger>
                          <DropdownMenu.Content>
                            <DropdownMenu.Item asChild>
                              <Link to={`/inputs?id=${input.id}`}>Open</Link>
                            </DropdownMenu.Item>
                            <DropdownMenu.Separator />
                            <DropdownMenu.Item color="red" onClick={() => deleteInput(index)}>
                              Delete
                            </DropdownMenu.Item>
                          </DropdownMenu.Content>
                        </DropdownMenu.Root>
                      </Flex>
                    </Card>
                  ))}
                </Flex>

                {inputs.isPending && (
                  <Skeleton height="32px" width="110px" />
                )}

                {inputs.error && (
                  <Flex align="center" gap="4" py="1">
                    <Text size="1">Something went wrong 😭</Text>
                    <Button variant="soft" size="1" type="button" onClick={inputs.refetch}>
                      <ReloadIcon /> Retry
                    </Button>
                  </Flex>
                )}

                {inputs.data && inputs.data.data.filter(input => !builderForm.inputs.find(i2 => i2.id === input.id)).length > 0 && (
                  <Popover.Root>
                    <Popover.Trigger>
                      <Box>
                        <Button color="purple" variant="soft" type="button">
                          <PlusIcon />
                          Add input
                        </Button>
                      </Box>
                    </Popover.Trigger>
                    <Popover.Content>
                      <Flex direction="column" gap="5">
                        {inputs.data?.data && inputs.data?.data.filter(input => !builderForm.inputs.find(i2 => i2.id === input.id)).map(input => (
                          <Popover.Close key={`new.input.${input.id}`}>
                            <Flex direction="column" gap="1" onClick={() => addInput(input)}>
                              <Text as="div" size="2" weight="bold">{input.name}</Text>
                              <Text as="div" color="gray" size="2">{inputDataTypes[input.data_type - 1]}</Text>
                            </Flex>
                          </Popover.Close>
                        ))}
                      </Flex>
                    </Popover.Content>
                  </Popover.Root>
                )}

                {formErrors.inputs && (
                  <Text color="red" size="1" weight="regular">{formErrors.inputs}</Text>
                )}
              </Flex>
            )}

            {builderId && builderId !== 'new' && (
              <>
                <Separator my="2" size="4" />

                <Flex direction="row">
                  <AlertDialog.Root>
                    <AlertDialog.Trigger>
                      <Button type="button" color="red">Delete builder</Button>
                    </AlertDialog.Trigger>
                    <AlertDialog.Content maxWidth="450px">
                      <AlertDialog.Title>Delete builder</AlertDialog.Title>
                      <AlertDialog.Description size="2">
                        Are you sure? This action is not reversible
                      </AlertDialog.Description>

                      <Flex gap="3" mt="4" justify="end">
                        <AlertDialog.Cancel>
                          <Button variant="soft" color="gray">
                            Cancel
                          </Button>
                        </AlertDialog.Cancel>
                        <AlertDialog.Action>
                          <Button type="button" variant="solid" color="red" onClick={dangerDelete}>
                            Delete
                          </Button>
                        </AlertDialog.Action>
                      </Flex>
                    </AlertDialog.Content>
                  </AlertDialog.Root>
                </Flex>
              </>
            )}

            <Flex justify="end" align="center" gap="2" px="4" py="2" className="bg-white border-t border-t-gray-200 fixed bottom-0 left-0 right-0 z-30">
              <Button type="button" variant="soft" onClick={discardChanges}>Discard</Button>
              <Button type="button" variant="solid" onClick={saveChanges} color="purple">Save</Button>
            </Flex>
          </Flex>
        </form>
      )}
    </>
  );
}

export {
  BuilderForm
}