import { Flex, Link as RadixLink, Text, TextArea, Button, Box, TextField, Skeleton, Select, Separator, CheckboxCards, Heading, Switch, AlertDialog, Card, IconButton } from '@radix-ui/themes';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteCourse, cancelCourse, publishCourse, upsertCourse } from '../api/courses';
import { getBuilders, getBuilder } from '../api/builders';
import { upsertKnowledge, deleteKnowledge } from '../api/knowledges';
import toast from 'react-hot-toast';
import { Cross1Icon, FileIcon, PlusIcon, ReloadIcon, UploadIcon } from '@radix-ui/react-icons';
import { courseRunStatus, courseStatus } from '../api/utils';

const maxFileSize = 20 * 1024 * 1024; // 20MB in bytes
const allowedFileMIMETypes = ['application/pdf', 'image/png', 'image/jpeg', 'text/plain', 'video/mp4']

const courseLanguages = [
  {name: 'Spanish', value: 'spanish'},
  {name: 'English', value: 'english'},
  {name: 'Portuguese', value: 'portuguese'},
];

function CourseForm({ isPending: courseIsPending, error: courseError, data: courseData, courseForm, setCourseForm }) {
  const { search } = useLocation();
  const searchURL = (id) => {
    const params = new URLSearchParams(search);
    params.set('course_id', id);
    return params.toString();
  }

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const courseId = searchParams.get('course_id');

  const [formErrors, setFormErrors] = useState({})
  const [knowledgeFormErrors, setKnowledgeFormErrors] = useState([])

  const builders = useQuery({ queryKey: ['/builder'], queryFn: getBuilders })
  const builder = useQuery({ queryKey: [`/builder/${courseForm.builder_id}`], queryFn: () => courseForm.builder_id ? getBuilder(courseForm.builder_id) : null })
  
  const queryClient = useQueryClient();

  const knowledgeMutation = useMutation({
    mutationFn: async ({ course_id, knowledge, index }) => ({ id: await upsertKnowledge({...knowledge, course_id}), index }),
    onSuccess: ({ id, index }) => {
      queryClient.invalidateQueries({ queryKey: ['/course/id'] });
      toast.success('Success! 🎉');
      const knowledge = courseForm.knowledges[index];
      setKnowledge({...knowledge, id}, index);
    },
    onError: (error) => {
      toast.error(error.data?.message ?? 'Something went wrong 🥲');
      console.error(error);
    }
  });

  const knowledgeDeleteMutation = useMutation({
    mutationFn: async ({ knowledge_id, index }) => {
      await deleteKnowledge(knowledge_id);
      return index;
    },
    onSuccess: ({ index }) => {
      queryClient.invalidateQueries({ queryKey: ['/course/id'] });
      toast.success('Knowledge deleted! 🫠')
      removeKnowledge(index);
    },
    onError: (error) => {
      toast.error(error.data?.message ?? 'Something went wrong 🥲');
      console.error(error);
    }
  });

  const courseMutation = useMutation({
    mutationFn: upsertCourse,
    onSuccess: (id) => {
      queryClient.invalidateQueries({ queryKey: ['/course'] });
      queryClient.invalidateQueries({ queryKey: ['/course/id'] });
      toast.success('Success! 🎉')

      if (id) {
        navigate({search: searchURL(id)});
      }
    },
    onError: (error) => {
      toast.error(error.data?.message ?? 'Something went wrong 🥲');
      console.error(error);
    }
  });

  const cancelMutation = useMutation({
    mutationFn: cancelCourse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/course'] });
      queryClient.invalidateQueries({ queryKey: ['/course/id'] });
      toast.success('Course generation cancelled! 🛑')
    },
    onError: (error) => {
      toast.error(error.data?.message ?? 'Something went wrong 🥲');
      console.error(error);
    }
  });

  const publishMutation = useMutation({
    mutationFn: publishCourse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/course'] });
      queryClient.invalidateQueries({ queryKey: ['/course/id'] });
      toast.success('Course published! ✅')
    },
    onError: (error) => {
      toast.error(error.data?.message ?? 'Something went wrong 🥲');
      console.error(error);
    }
  });

  const deleteMutation = useMutation({
    mutationFn: deleteCourse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/course'] });
      toast.success('Course deleted! 🫠')

      navigate({search: searchURL('')});
    },
    onError: (error) => {
      toast.error(error.data?.message ?? 'Something went wrong 🥲');
      console.error(error);
    }
  });

  const resetForm = useCallback((id, data, force=false) => {
    if (id === 'new' || !data) {
      setCourseForm({
        id: undefined,
        name: '',
        description: '',
        languages: [],
        builder_id: undefined,
        knowledges: [],
        responses: [],
      });
    } else if ((data && data.data.course.id !== courseForm.id) || force) {
      setCourseForm({
        id: data.data.course.id,
        name: data.data.course.name,
        description: data.data.course.description,
        languages: data.data.course.languages,
        builder_id: data.data.course.builder_id,
        knowledges: data.data.knowledges,
        responses: data.data.responses,
      });
    }
  }, [courseForm.id, setCourseForm])

  const { reset: courseReset } = courseMutation;
  const { reset: knowledgeReset } = knowledgeMutation;

  useEffect(() => {
    courseReset();
    knowledgeReset();
    resetForm(courseId, courseData);
  }, [courseId, courseData, courseReset, knowledgeReset, resetForm]);

  useEffect(() => {
    courseReset();
    knowledgeReset();
  }, [courseForm, courseReset, knowledgeReset]);

  const { error: courseMutationError } = courseMutation;
  useEffect(() => {
    setFormErrors(courseMutationError?.data ?? {})
  }, [courseMutationError, setFormErrors]);

  const { error: knowledgeMutationError, variables: knowledgeMutationVariables } = knowledgeMutation;
  useEffect(() => {
    if (knowledgeMutationVariables) {
      setKnowledgeFormErrors(prev => {
        const knowledgeErrors = [...prev];
        knowledgeErrors[knowledgeMutationVariables.index] = knowledgeMutationError?.data ?? {};
        return knowledgeErrors;
      });
    }
  }, [knowledgeMutationError, knowledgeMutationVariables]);

  const { error: knowledgeDeleteMutationError, variables: knowledgeDeleteMutationVariables } = knowledgeDeleteMutation;
  useEffect(() => {
    if (knowledgeDeleteMutationVariables) {
      setKnowledgeFormErrors(prev => {
        const knowledgeDeleteErrors = [...prev];
        knowledgeDeleteErrors[knowledgeDeleteMutationVariables.index] = knowledgeDeleteMutationError?.data ?? {};
        return knowledgeDeleteErrors;
      });
    }
  }, [knowledgeDeleteMutationError, knowledgeDeleteMutationVariables]);

  const { data: builderData } = builder;
  useEffect(() => {
    if (courseId === 'new') {
      const responses = builderData?.data.inputs.map(input => {
        let value = undefined;
        if (input.data_type === 2) {
          value = 'false';
        } else if (input.required) {
          value = '';
        }

        const res = {
          input_id: input.id,
          name: input.name,
          data_type: input.data_type,
          permitted_values: input.permitted_values,
          required: input.required,
          behavior: input.behavior,
          value,
        }

        return res;
      }) ?? [];
      setCourseForm(prev => ({ ...prev, responses }))
    }
  }, [courseId, builderData, setCourseForm])

  const discardChanges = () => {
    resetForm(courseId, courseData, true);
  }

  const saveChanges = () => {
    courseMutation.reset();

    if (courseForm.knowledges.find(k => !k.id)) {
      toast.error('You need to upload all knowledges first!');
      return;
    }

    courseMutation.mutate(courseForm);
  }

  const dangerPublish = () => {
    publishMutation.reset();
    publishMutation.mutate(courseId);
  }

  const dangerCancel = () => {
    cancelMutation.reset()
    cancelMutation.mutate(courseId);
  }

  const dangerDelete = () => {
    deleteMutation.reset()
    deleteMutation.mutate(courseId);
  }

  const selectBuilder = (builderId) => {
    setCourseForm(prev => ({
      ...prev,
      builder_id: Number.parseInt(builderId),
      responses: prev.builder_id !== Number.parseInt(builderId) ? [] : prev.responses,
    }));

    builder.refetch();
  }

  const setResponse = (value, index) => {
    const responses = [...courseForm.responses];
    responses[index] = { ...responses[index], value };
  
    setCourseForm(prev => ({
      ...prev,
      responses
    }));
  }

  const addKnowledge = () => {
    setCourseForm(prev => ({
      ...prev,
      knowledges: [
        ...prev.knowledges,
        {id: undefined, name: '', source_file: undefined}
      ],
    }));

    setKnowledgeFormErrors(prev => [...prev, {}]);
  }
  
  const removeKnowledge = (index) => {
    const knowledges = [...courseForm.knowledges]
    knowledges.splice(index, 1);

    setCourseForm(prev => ({
      ...prev,
      knowledges,
    }));

    const knowledgeErrors = [...knowledgeFormErrors];
    knowledgeErrors.splice(index, 1);
    setKnowledgeFormErrors(knowledgeErrors);
  }

  const setKnowledge = (item, index) => {
    const knowledges = [...courseForm.knowledges]
    knowledges[index] = item

    setCourseForm(prev => ({
      ...prev,
      knowledges,
    }));
  }

  const saveKnowledge = (index) => {
    const source_file = courseForm.knowledges[index].source_file;

    if (!source_file) {
      toast.error('File is missing');
      return;
    } else if (source_file.size > maxFileSize) {
      toast.error('File size cannot be more than 20MB');
      return;
    } else if (!allowedFileMIMETypes.includes(source_file.type)) {
      toast.error('File type is unsupported');
      return;
    }

    knowledgeMutation.reset();
    knowledgeMutation.mutate({ knowledge: courseForm.knowledges[index], index, course_id: courseForm.id });
  }

  const dangerRemoveKnowledge = (index) => {
    const knowledgeId = courseForm.knowledges[index].id;
    if (!knowledgeId) {
      removeKnowledge(index);
      return;
    }

    knowledgeDeleteMutation.reset();
    knowledgeDeleteMutation.mutate({ index, knowledge_id: knowledgeId });
  }

  const isProcessing = () => courseId !== 'new' && [courseRunStatus.pending, courseRunStatus.inProgress].includes(courseData?.data.course.run_status);

  const cropFilename = (filename) => {
    if (filename.length < 23) {
      return filename;
    } else {
      const n = filename.length;
      return `${filename.substring(0, 10)}...${filename.substring(n-10)}`
    }
  }

  return (
    <>
      {courseIsPending && (
        <Flex direction="column" gap="5">
          <Skeleton height="56px" width="300px" />
          <Skeleton height="88px" width={{initial: "300px", md: "400px"}} />
          <Skeleton height="72px" width={{initial: "300px", md: "500px"}} />
        </Flex>
      )}

      {!courseError && !courseIsPending && (
        <form>
          <Flex direction="column" gap="5">
            <Box maxWidth="300px">
              <Text as="label" size="2" weight="bold">
                <Box pb="1">Name</Box>
                <TextField.Root
                  name="name"
                  size="2"
                  placeholder="e.g. Curso de inglés básico"
                  disabled={isProcessing()}
                  value={courseForm.name}
                  onChange={(e) => setCourseForm(prev => ({ ...prev, name: e.target.value }))}
                  required
                />
                {formErrors.name && (
                  <Text color="red" size="1" weight="regular">{formErrors.name}</Text>
                )}
              </Text>
            </Box>

            <Box maxWidth={{initial: "300px", md: "400px"}}>
              <Text as="label" size="2" weight="bold">
                <Box pb="1">Description</Box>
                <TextArea
                  name="description"
                  size="2"
                  rows="4"
                  placeholder="e.g. Este curso trata sobre ..."
                  disabled={isProcessing()}
                  value={courseForm.description}
                  onChange={(e) => setCourseForm(prev => ({ ...prev, description: e.target.value }))}
                  required
                />
                {formErrors.description && (
                  <Text color="red" size="1" weight="regular">{formErrors.description}</Text>
                )}
              </Text>
            </Box>

            <Box maxWidth="500px">
              <Text as="label" size="2" weight="bold">
                <Box pb="1">Languages</Box>
                <CheckboxCards.Root
                  columns={{ initial: '1', sm: '3' }}
                  value={courseForm.languages}
                  disabled={isProcessing()}
                  onValueChange={value => setCourseForm(prev => ({...prev, languages: value}))}
                >
                  {courseLanguages.map(language => (
                    <CheckboxCards.Item key={`checkbox.language.${language.value}`} value={language.value}>
                      <Text weight="bold">{language.name}</Text>
                    </CheckboxCards.Item>
                  ))}
                </CheckboxCards.Root>
                {formErrors.languages && (
                  <Text color="red" size="1" weight="regular">{formErrors.languages}</Text>
                )}
              </Text>
            </Box>

            <Separator my="2" size="4" />

            <Flex direction="column" gap="2" maxWidth="500px">
              <Heading as="h6" size="2">Knowledge</Heading>

              <Flex direction="column" gap="3">
                {courseForm.knowledges.length === 0 && (
                  <Box maxWidth="300px">
                    <Card>
                      <Text as="div" size="2" weight="bold">💡 No knowledge</Text>
                      <Text as="div" color="gray" size="2">
                        {courseId === 'new' && 'To use specific knowledge, add them with the button below'}
                        {courseId !== 'new' && 'There is no knowledge associated with this course'}
                      </Text>
                    </Card>
                  </Box>
                )}

                {courseForm.knowledges.length > 0 && courseForm.knowledges.map((item, index) => (
                  <Card key={`course.${courseId}.knowledges[${index}]`}>
                    <Flex direction="column" gap="2">
                      <Flex justify="between" gap="4">
                        <Box flexGrow="1">
                          <Text as="label" size="2" weight="bold">
                            <Box pb="1">Name</Box>
                            <TextField.Root
                              name={`name[${index}]`}
                              size="2"
                              placeholder="e.g. Rules guide"
                              disabled={item.id}
                              value={item.name}
                              onChange={(e) => setKnowledge({...item, name: e.target.value}, index)}
                              required
                            />
                            {knowledgeFormErrors[index]?.name && (
                            <Text color="red" size="1" weight="regular">{knowledgeFormErrors[index].name}</Text>
                          )}
                          </Text>
                        </Box>
                        
                        <Flex gap="1">
                          {!item.id && (
                            <IconButton type="button" variant="soft" size="1" color="purple" onClick={() => saveKnowledge(index)}>
                              <UploadIcon />
                            </IconButton>
                          )}

                          {!item.id && !isProcessing() && (
                            <IconButton type="button" variant="soft" size="1" onClick={() => dangerRemoveKnowledge(index)}>
                              <Cross1Icon />
                            </IconButton>
                          )}

                          {item.id && !isProcessing() && (
                            <AlertDialog.Root>
                              <AlertDialog.Trigger>
                                <IconButton type="button" variant="soft" size="1">
                                  <Cross1Icon />
                                </IconButton>
                              </AlertDialog.Trigger>
                              <AlertDialog.Content maxWidth="450px">
                                <AlertDialog.Title>Delete knowledge</AlertDialog.Title>
                                <AlertDialog.Description size="2">
                                  Are you sure? This action is not reversible
                                </AlertDialog.Description>
        
                                <Flex gap="3" mt="4" justify="end">
                                  <AlertDialog.Cancel>
                                    <Button variant="soft" color="gray">
                                      Cancel
                                    </Button>
                                  </AlertDialog.Cancel>
                                  <AlertDialog.Action>
                                    <Button type="button" variant="solid" color="red" onClick={() => dangerRemoveKnowledge(index)}>
                                      Delete
                                    </Button>
                                  </AlertDialog.Action>
                                </Flex>
                              </AlertDialog.Content>
                            </AlertDialog.Root>
                          )}
                        </Flex>
                      </Flex>

                      <Box maxWidth="400px">
                        <Text as="label" size="2" weight="bold">
                          {!item.id && (
                            <>
                              <Box pb="1">
                                <Text>Source</Text>
                              </Box>
                              <Button type="button" variant="soft" color="purple" size="2" disabled={item.id} asChild>
                                <Box>
                                  <FileIcon />
                                  Select a file
                                  {item.source_file?.name && ` (${cropFilename(item.source_file?.name)})`}
                                  <input
                                    type="file"
                                    name={`source_file[${index}]`}
                                    className="hidden"
                                    placeholder="Add a source URL containing the data"
                                    disabled={item.id}
                                    onChange={(e) => setKnowledge({ ...item, source_file: e.target.files[0] ?? item.source_file }, index)}
                                    accept={allowedFileMIMETypes.join(', ')}
                                  />
                                  </Box>
                              </Button>
                            </>
                          )}
                          {knowledgeFormErrors[index]?.source_file && (
                            <Text color="red" size="1" weight="regular">{knowledgeFormErrors[index].source_file}</Text>
                          )}
                        </Text>
                        <Flex gap="4" pt="1">
                          <RadixLink size="1" href={`${process.env.REACT_APP_API_BASE_URL}/knowledge/${item.id}/source`} target='_blank' weight="medium" color="purple" underline="always">
                            View source
                          </RadixLink>
                          {item.status === 2 && (
                            <RadixLink size="1" href={`${process.env.REACT_APP_API_BASE_URL}/knowledge/${item.id}/content`} target='_blank' weight="medium" color="purple" underline="always">
                              View transcription
                            </RadixLink>
                          )}
                        </Flex>
                      </Box>
                    </Flex>
                  </Card>
                ))}
              </Flex>

              <Box>
                <Button color="purple" variant="soft" type="button" onClick={addKnowledge} disabled={isProcessing()}>
                  <PlusIcon />
                  Add knowledge
                </Button>
              </Box>

              {formErrors.knowledges && (
                <Text color="red" size="1" weight="regular">{formErrors.knowledges}</Text>
              )}
            </Flex>


            {courseId === 'new' && (
              <>
                <Separator my="2" size="4" />

                <Box maxWidth="300px">
                  <Text as="label" size="2" weight="bold">
                    <Box pb="1">Builder</Box>

                    {builders.isPending && (
                      <Skeleton height="32px" width="150px" />
                    )}

                    {builders.error && (
                      <Flex align="center" gap="4" py="2">
                        <Text size="1">Something went wrong 😭</Text>
                        <Button variant="soft" size="1" type="button" onClick={builders.refetch}>
                          <ReloadIcon /> Retry
                        </Button>
                      </Flex>
                    )}

                    {builders.data && (
                      <Select.Root
                        size="2"
                        name="builder_id"
                        disabled={courseId !== 'new'}
                        value={courseForm.builder_id ?? ''}
                        onValueChange={selectBuilder}
                        required
                      >
                        <Select.Trigger placeholder="Pick a builder to use" />
                        <Select.Content>
                          <Select.Group>
                            {builders.data && builders.data.data.map(item => (
                              <Select.Item key={`builder_id.${item.id}`} value={item.id}>{item.name}</Select.Item>
                            ))}
                          </Select.Group>
                        </Select.Content>
                      </Select.Root>
                    )}

                    {formErrors.builder_id && (
                      <Text as="div" color="red" size="1" weight="regular">{formErrors.builder_id}</Text>
                    )}
                  </Text>
                </Box>
              </>
            )}

            {(courseForm.builder_id || courseId !== 'new') && (
              <>
                <Separator my="2" size="4" />

                <Flex direction="column" gap="5" maxWidth="300px">
                  <Heading as="h6" size="4">Responses</Heading>

                  {builder.isPending && (
                    <Flex direction="column" gap="5">
                      <Skeleton height="56px" width="300px" />
                      <Skeleton height="56px" width="200px" />
                      <Skeleton height="56px" width="300px" />
                    </Flex>
                  )}

                  {builder.error && (
                    <Flex align="center" gap="4" py="2">
                      <Text size="1">Something went wrong 😭</Text>
                      <Button variant="soft" size="1" type="button" onClick={builders.refetch}>
                        <ReloadIcon /> Retry
                      </Button>
                    </Flex>
                  )}

                  {courseForm.responses.length > 0 && (
                    <Flex direction="column" gap="5">
                      {courseForm.responses.map((response, index) => (
                        <Box maxWidth="300px" key={`responses[${index}]`} className={response.data_type === 4 ? 'hidden' : ''}>
                          <Text as="label" size="2" weight="bold">
                            <Box pb="1">{response.name}</Box>

                            {(response.data_type === 1 || response.data_type === 3) && response.permitted_values?.length === 0 && (
                              <TextField.Root
                                size="2"
                                name={`responses[${index}]`}
                                disabled={courseId !== 'new'}
                                value={response.value}
                                onChange={(e) => setResponse(e.target.value, index)}
                                required
                              />
                            )}

                            {response.permitted_values?.length > 0 && (
                              <Select.Root
                                size="2"
                                name={`responses[${index}]`}
                                disabled={courseId !== 'new'}
                                value={response.value.toLowerCase()}
                                onValueChange={value => setResponse(value, index)}
                                required
                              >
                                <Select.Trigger placeholder="Pick a value" />
                                <Select.Content>
                                  <Select.Group>
                                    {response.permitted_values.map(item => (
                                      <Select.Item key={`responses[${index}][${item}]`} value={item.toLowerCase()}>{item}</Select.Item>
                                    ))}
                                  </Select.Group>
                                </Select.Content>
                              </Select.Root>
                            )}

                            {response.data_type === 2 && (
                              <Box py="1">
                                <Switch
                                  size="2"
                                  name={`responses[${index}]`}
                                  checked={response.value === 'true'}
                                  disabled={courseId !== 'new'}
                                  onCheckedChange={value => setResponse(`${value}`, index)}
                                  required
                                />
                              </Box>
                            )}

                            {formErrors[`responses[${index}]`] && (
                              <Box>
                                <Text color="red" size="1" weight="regular">{formErrors[`responses[${index}]`]}</Text>
                              </Box>
                            )}
                          </Text>
                        </Box>
                      ))}
                    </Flex>
                  )}

                  {formErrors.responses && (
                    <Text color="red" size="1" weight="regular">{formErrors.responses}</Text>
                  )}
                </Flex>
              </>
            )}

            {courseId && courseId !== 'new' && (
              <>
                <Separator my="2" size="4" />

                <Flex direction="row" gap="2">
                  {isProcessing() && (
                    <AlertDialog.Root>
                      <AlertDialog.Trigger>
                        <Button type="button" color="orange" variant="soft">Cancel generation</Button>
                      </AlertDialog.Trigger>
                      <AlertDialog.Content maxWidth="450px">
                        <AlertDialog.Title>Cancel generation</AlertDialog.Title>
                        <AlertDialog.Description size="2">
                          Are you sure? This will stop the current course generation
                          and you will be required to click the regenerate button again
                        </AlertDialog.Description>

                        <Flex gap="3" mt="4" justify="end">
                          <AlertDialog.Cancel>
                            <Button variant="soft" color="gray">
                              Cancel
                            </Button>
                          </AlertDialog.Cancel>
                          <AlertDialog.Action>
                            <Button type="button" color="orange" variant="soft" onClick={dangerCancel}>
                              Cancel
                            </Button>
                          </AlertDialog.Action>
                        </Flex>
                      </AlertDialog.Content>
                    </AlertDialog.Root>
                  )}

                  {courseId !== 'new' && !isProcessing() && (
                    <AlertDialog.Root>
                      <AlertDialog.Trigger>
                        <Button type="button" color="red">Delete course</Button>
                      </AlertDialog.Trigger>
                      <AlertDialog.Content maxWidth="450px">
                        <AlertDialog.Title>Delete course</AlertDialog.Title>
                        <AlertDialog.Description size="2">
                          Are you sure? This action is not reversible
                        </AlertDialog.Description>

                        <Flex gap="3" mt="4" justify="end">
                          <AlertDialog.Cancel>
                            <Button variant="soft" color="gray">
                              Cancel
                            </Button>
                          </AlertDialog.Cancel>
                          <AlertDialog.Action>
                            <Button type="button" variant="solid" color="red" onClick={dangerDelete}>
                              Delete
                            </Button>
                          </AlertDialog.Action>
                        </Flex>
                      </AlertDialog.Content>
                    </AlertDialog.Root>
                  )}
                </Flex>
              </>
            )}

            <Flex justify="end" align="center" gap="2" px="4" py="2" className="bg-white border-t border-t-gray-200 fixed bottom-0 left-0 right-0 z-30">
              <Button type="button" variant="soft" onClick={discardChanges} disabled={isProcessing()}>
                Discard
              </Button>
              {courseId !== 'new' && (
                <Button type="button" variant="soft" color="purple" disabled={isProcessing()} onClick={saveChanges}>
                  Update course
                </Button>
              )}
              {courseId !== 'new' && !isProcessing() && courseData?.data.course.status === courseStatus.content && (
                <AlertDialog.Root>
                  <AlertDialog.Trigger>
                    <Button type="button" variant="solid" color="purple" disabled={courseData?.data.course.run_status !== courseRunStatus.processed}>
                      Publish
                    </Button>
                  </AlertDialog.Trigger>
                  <AlertDialog.Content maxWidth="450px">
                    <AlertDialog.Title>Publish course</AlertDialog.Title>
                    <AlertDialog.Description size="2">
                      Are you sure? This action will send the course to La Teacher's platform
                    </AlertDialog.Description>

                    <Flex gap="3" mt="4" justify="end">
                      <AlertDialog.Cancel>
                        <Button variant="soft" color="gray">
                          Cancel
                        </Button>
                      </AlertDialog.Cancel>
                      <AlertDialog.Action>
                        <Button type="button" variant="solid" color="purple" onClick={dangerPublish}>
                          Publish
                        </Button>
                      </AlertDialog.Action>
                    </Flex>
                  </AlertDialog.Content>
                </AlertDialog.Root>
              )}

              {courseId === 'new' && (
                <AlertDialog.Root>
                  <AlertDialog.Trigger>
                    <Button type="button" variant="solid" color="purple" disabled={isProcessing()}>
                      Create
                    </Button>
                  </AlertDialog.Trigger>
                  <AlertDialog.Content maxWidth="450px">
                    <AlertDialog.Title>
                      Create course
                    </AlertDialog.Title>
                    <AlertDialog.Description size="2">
                      Are you sure? You won't be able to make changes until it's processed
                    </AlertDialog.Description>

                    <Flex gap="3" mt="4" justify="end">
                      <AlertDialog.Cancel>
                        <Button variant="soft" color="gray">
                          Cancel
                        </Button>
                      </AlertDialog.Cancel>
                      <AlertDialog.Action>
                        <Button type="button" variant="solid" color="purple" onClick={saveChanges}>
                          Create
                        </Button>
                      </AlertDialog.Action>
                    </Flex>
                  </AlertDialog.Content>
                </AlertDialog.Root>
              )}
            </Flex>
          </Flex>
        </form>
      )}
    </>
  );
}

export {
  CourseForm
}