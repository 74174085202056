import { Em, Flex, Text, Button, Box, Separator, Heading, Skeleton, Select, AlertDialog, TextField, TextArea, Switch } from '@radix-ui/themes';
import { CheckCircledIcon, EnvelopeClosedIcon, EnvelopeOpenIcon } from '@radix-ui/react-icons';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { getCourseContent, regenerateCourse, updateCourseTemplate } from '../api/courses';
import { courseRunStatus, courseStatus } from '../api/utils';
import toast from 'react-hot-toast';

const courseLanguages = {
  spanish: 'Spanish',
  english: 'English',
  portuguese: 'Portuguese',
}

const mediaHeader = {
  spanish: 'Inserte una imagen o video para acompañar aquí',
  english: 'Insert an image or video to accompany here',
  portuguese: 'Insira aqui uma imagem ou vídeo para acompanhar',
}

function CourseContent({ course: courseData, contentForm, setContentForm }) {
  const [searchParams] = useSearchParams();
  const courseId = searchParams.get('course_id');

  const content = useQuery({ queryKey: ['/course/id/content'], queryFn: () => courseId && courseId !== 'new' ? getCourseContent(courseId, courseLanguage) : null })

  const [courseLanguage, setCourseLanguage] = useState(courseData.data.course.languages[0]);

  const [formErrors, setFormErrors] = useState({})

  const { refetch: contentRefetch } = content;
  useEffect(() => {
    contentRefetch();
  }, [contentRefetch, courseLanguage, courseId]);

  const queryClient = useQueryClient();

  const templateMutation = useMutation({
    mutationFn: updateCourseTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/course'] });
      queryClient.invalidateQueries({ queryKey: ['/course/id'] });
      queryClient.invalidateQueries({ queryKey: ['/course/id/content'] });
      toast.success('Success! 🎉')
    },
    onError: (error) => {
      toast.error(error.data?.message ?? 'Something went wrong 🥲');
      console.error(error);
    }
  });

  const regenerateMutation = useMutation({
    mutationFn: regenerateCourse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/course'] });
      queryClient.invalidateQueries({ queryKey: ['/course/id'] });
      queryClient.invalidateQueries({ queryKey: ['/course/id/content'] });
      toast.success('Course generation started! ✅')
    },
    onError: (error) => {
      toast.error(error.data?.message ?? 'Something went wrong 🥲');
      console.error(error);
    }
  });

  const isProcessing = () => courseId !== 'new' && [courseRunStatus.pending, courseRunStatus.inProgress].includes(courseData?.data.course.run_status);

  const resetForm = useCallback((id, data, language, force=false) => {
    if (id === 'new' || !data) {
      setContentForm({
        id: undefined,
        modules: [],
        language: language,
      });
    } else if ((data && data.data.id !== contentForm.id) || language !== contentForm.language || force) {
      setContentForm({
        id: data.data.id,
        modules: data.data.modules
      });
    }
  }, [contentForm.id, contentForm.language, setContentForm]);

  const { reset: templateReset } = templateMutation;
  const { reset: regenerateReset } = regenerateMutation;

  const { error: templateMutationError } = templateMutation;
  useEffect(() => {
    setFormErrors(templateMutationError?.data ?? {})
  }, [templateMutationError]);

  useEffect(() => {
    templateReset();
    regenerateReset();
  }, [contentForm, templateReset, regenerateReset]);

  const { data: contentData } = content;
  useEffect(() => {
    templateReset();
    regenerateReset();
    resetForm(courseId, contentData, courseLanguage);
  }, [courseId, courseLanguage, contentData, resetForm, templateReset, regenerateReset]);

  const generationInProgressMessage = (contentType) => {
    if (isProcessing()) {
      return `The contents for this ${contentType} are being generated`;
    }

    const status = courseData?.data.course.status;
    const runStatus = courseData?.data.course.run_status;

    if (runStatus === courseRunStatus.cancelled) {
      return `The contents for this ${contentType} were cancelled. Click "Regenerate" to generate them.`;
    } else if (runStatus === courseRunStatus.failed) {
      return `The contents for this ${contentType} failed to generate. Click "Regenerate" to retry or contact support.`;
    } else if (status === courseStatus.empty) {
      return `There are no contents for this ${contentType} and it's not being processed. Click "Regenerate" to retry or contact support.`;
    } else if (status === courseStatus.template) {
      return `Modify the generated template for this ${contentType} and click "Continue" to generate the contents.`;
    } else if (status === courseStatus.content) {
      return `There are no contents for this ${contentType}.`;
    }
  }

  const setContentModule = (module, index) => {
    setContentForm(prev => {
      const modules = [...prev.modules];
      modules[index] = module;
      return { ...prev, modules };
    });
  }

  const setContentLesson = (lesson, index, moduleIndex) => {
    setContentForm(prev => {
      const lessons = [...prev.modules[moduleIndex].lessons];
      lessons[index] = lesson;
      const modules = [...prev.modules];
      modules[moduleIndex] = { ...modules[moduleIndex], lessons };
      return { ...prev, modules };
    });
  }

  const primaryActionLabel = () => {
    const status = courseData?.data.course.status;
    const runStatus = courseData?.data.course.run_status;

    if (status === courseStatus.template && runStatus === courseRunStatus.processed) {
      return 'Continue';
    } else {
      return 'Regenerate';
    }
  }

  const discardChanges = () => {
    resetForm(courseId, contentData, courseLanguage, true);
  }

  const saveChanges = () => {
    templateMutation.reset();
    templateMutation.mutate(contentForm);
  }

  const regenerate = () => {
    regenerateMutation.reset();
    regenerateMutation.mutate({ id: courseId });
  }

  return (
    <>
      {content.isPending && (
        <Flex direction="column" gap="5">
          <Skeleton height="40px" width={{initial: "300px", md: "500px"}} />
          <Skeleton height="50px" width="200px" />
          <Separator orientation="horizontal" size="4" />
          <Skeleton height="150px" width={{initial: "300px", md: "600px"}} />
          <Skeleton height="150px" width={{initial: "300px", md: "600px"}} />
          <Skeleton height="150px" width={{initial: "300px", md: "600px"}} />
        </Flex>
      )}

      {!content.error && !content.isPending && content.data && (
        <Flex direction="column" gap="4">
          <Flex direction="column" gap="4">
            <Flex direction="column" gap="1">
              <Flex gap="2">
                <Text size="1" weight="medium" color="gray">Course</Text>
                <Separator orientation="vertical" />
                <Text size="1" color="gray" weight="medium">{courseData.data.course.name}</Text>
              </Flex>
              <Text size="2">{courseData.data.course.description}</Text>
            </Flex>

            {courseData?.data.course.status === courseStatus.content && (
              <Box>
                <Text as="label" size="1" weight="medium">
                  <Box pb="1">Language</Box>
                  <Select.Root value={courseLanguage} onValueChange={setCourseLanguage}>
                    <Select.Trigger />
                    <Select.Content>
                      <Select.Group>
                        {courseData.data.course.languages.map(key => (
                          <Select.Item key={key} value={key}>{courseLanguages[key]}</Select.Item>
                        ))}
                      </Select.Group>
                    </Select.Content>
                  </Select.Root>
                </Text>
              </Box>
            )}

            <Separator orientation="horizontal" size="4" />
          </Flex>

          <Flex direction="column" gap="4">
            {contentForm.modules.length === 0 && (
              <Flex direction="column" gap="1">
                <Text size="1" weight="medium" color="gray">No modules</Text>
                <Heading as="h3" size="2" weight="regular">
                  {generationInProgressMessage('course')}
                </Heading>
              </Flex>
            )}

            {contentForm.modules.map((module, moduleIndex) => (
              <Flex key={`module.${module.position}`} direction="column" gap="4">
                <Flex direction="column" gap="1">
                  <Text size="1" weight="medium" color="gray">Module {module.position+1}</Text>
                  {(courseData?.data.course.status !== courseStatus.template || module.lessons.find(l => l.complete)) && (
                    <Heading as="h3" size="3">{module.name}</Heading>
                  )}
                  {courseData?.data.course.status === courseStatus.template && !module.lessons.find(l => l.complete) && (
                    <Box maxWidth="400px">
                      <Text as="label" size="1">
                        This is the name for the module
                        <TextField.Root
                          name={`modules[${moduleIndex}].name`}
                          size="2"
                          placeholder="e.g. Introduction"
                          disabled={isProcessing()}
                          value={module.name}
                          onChange={(e) => setContentModule({ ...module, name: e.target.value }, moduleIndex)}
                          required
                        />
                        {formErrors.modules && formErrors.modules[moduleIndex]?.name && (
                          <Text as="div" color="red" size="1" weight="regular">{formErrors.modules[moduleIndex].name}</Text>
                        )}
                      </Text>
                    </Box>
                  )}
                </Flex>
                
                <Flex direction="column" gap="4" pl={{initial: "3", lg: "5"}}>
                  {module.lessons.length === 0 && (
                    <Flex direction="column" gap="1">
                      <Text size="1" weight="medium" color="gray">No lessons</Text>
                      <Heading as="h3" size="2" weight="regular">
                        {generationInProgressMessage('module')}
                      </Heading>
                    </Flex>
                  )}

                  {module.lessons.map((lesson, lessonIndex) => (
                    <Flex key={`modules[${moduleIndex}].lesson.${lesson.position}`} direction="column" gap="2">
                      <Flex direction="column" gap="1">
                        <Text size="1" weight="medium" color="gray">Lesson {lesson.position+1}</Text>
                        {(courseData?.data.course.status !== courseStatus.template || lesson.complete) && (
                          <Text size="2" color="gray">{lesson.name}</Text>
                        )}
                        {courseData?.data.course.status === courseStatus.template && !lesson.complete && (
                          <Flex direction="column" gap="3">
                            <Box>
                              <Text as="label" size="1">
                                This is the topic this lesson will be about:
                                <TextArea
                                  name={`modules[${moduleIndex}].lesson[${lessonIndex}].name`}
                                  size="2"
                                  placeholder="e.g. Introduction"
                                  disabled={isProcessing()}
                                  value={lesson.name}
                                  onChange={(e) => setContentLesson({ ...lesson, name: e.target.value }, lessonIndex, moduleIndex)}
                                  required
                                />
                                {formErrors.modules && formErrors.modules[moduleIndex]?.lessons && formErrors.modules[moduleIndex]?.lessons[lessonIndex]?.name && (
                                  <Text as="div" color="red" size="1" weight="regular">{formErrors.modules[moduleIndex].lessons[lessonIndex].name}</Text>
                                )}
                              </Text>
                            </Box>

                            <Box>
                              <Text as="label" py="1" size="1">
                                <Flex gap="1">
                                  🤓 Do you want a quiz in this lesson?
                                  <Switch
                                    size="2"
                                    color="green"
                                    name={`lesson[${lessonIndex}].quiz`}
                                    checked={lesson.data.has_quiz}
                                    onCheckedChange={value => isProcessing() ? true : setContentLesson({
                                      ...lesson,
                                      data: { ...lesson.data, has_quiz: value }
                                    }, lessonIndex, moduleIndex)}
                                    required
                                  />
                                </Flex>
                              </Text>
                            </Box>

                            <Box>
                              <Text as="label" py="1" size="1">
                                <Flex gap="1">
                                  🎯 Do you want an activity in this lesson?
                                  <Switch
                                    size="2"
                                    color="green"
                                    name={`lesson[${lessonIndex}].activity`}
                                    checked={lesson.data.has_activity}
                                    onCheckedChange={value => isProcessing() ? true : setContentLesson({
                                      ...lesson,
                                      data: { ...lesson.data, has_activity: value }
                                    }, lessonIndex, moduleIndex)}
                                    required
                                  />
                                </Flex>
                              </Text>
                            </Box>
                          </Flex>
                        )}
                        <Heading as="h4" size="2">{lesson.data.title}</Heading>
                      </Flex>

                      {!lesson.complete && courseData?.data.course.status !== courseStatus.template && (
                        <Heading as="h4" size="2" weight="regular">
                          {generationInProgressMessage('lesson')}
                        </Heading>
                      )}

                      {lesson.complete && (
                        <Flex direction="column" gap="2">
                          {lesson.data.contents.map((message, index) => (
                            <Flex key={`modules[${moduleIndex}].lessons[${lessonIndex}].messages[${index}]`} gap="2" align="start">
                              <Box>
                                <EnvelopeClosedIcon height="24px" width="12px" />
                              </Box>
                              <Text>
                                {index === 0 && lesson.data.media && (
                                  <Box><Em>{mediaHeader[courseLanguage]}</Em></Box>
                                )}
                                {message}
                              </Text>
                            </Flex>
                          ))}

                          {lesson.data.has_activity && (
                            <Flex gap="1" direction="column">
                              <Flex gap="1" direction="column">
                                <Text size="1" weight="medium" color="gray">Activity</Text>
                                <Heading as="h4" size="2">{lesson.data.activity.title}</Heading>
                              </Flex>

                              <Flex gap="2" align="start">
                                <Box>
                                  <EnvelopeClosedIcon height="24px" width="10px" />
                                </Box>
                                <Text size="2">
                                  {lesson.data.activity.media && (
                                    <Box><Em>[Inserte una imagen ú objeto de acompanamiento aquí]</Em></Box>
                                  )}
                                  {lesson.data.activity.content}
                                </Text>
                              </Flex>

                              {lesson.data.activity.response && (
                                <Flex gap="2" align="start">
                                  <Box>
                                    <EnvelopeOpenIcon height="24px" width="10px" />
                                  </Box>
                                  <Text size="2">
                                    <strong>Response: </strong>
                                    {lesson.data.activity.response}
                                  </Text>
                                </Flex>
                              )}
                            </Flex>
                          )}

                          {lesson.data.has_quiz && (
                            <Flex gap="1" direction="column">
                              <Flex gap="1" direction="column">
                                <Text size="1" weight="medium" color="gray">Quiz</Text>
                                <Heading as="h4" size="2">{lesson.data.quiz.title}</Heading>
                              </Flex>

                              <Flex gap="2" align="start">
                                <Box>
                                  <EnvelopeClosedIcon height="24px" width="10px" />
                                </Box>
                                <Text size="2">
                                  {lesson.data.quiz.media && (
                                    <Box><Em>[Inserte una imagen ú objeto de acompanamiento aquí]</Em></Box>
                                  )}
                                  {lesson.data.quiz.content}
                                </Text>
                              </Flex>

                              <Flex gap="1" direction="column">
                                {lesson.data.quiz.choices.map((choice, index) => (
                                  <Flex key={`modules[${moduleIndex}].lessons[${lessonIndex}].choices[${index}]`} gap="2" align="start">
                                    <Box>
                                      <CheckCircledIcon height="24px" width="10px" />
                                    </Box>
                                    <Text size="2" className={choice === lesson.data.quiz.answer || lesson.data.quiz.answer === 'any' ? 'bg-green-200' : ''}>
                                      {choice}
                                    </Text>
                                  </Flex>
                                ))}
                              </Flex>


                              {lesson.data.quiz.response && (
                                <Flex gap="2" align="start">
                                  <Box>
                                    <EnvelopeOpenIcon height="24px" width="10px" />
                                  </Box>
                                  <Text size="2">
                                    <strong>Response: </strong>
                                    {lesson.data.quiz.response}
                                  </Text>
                                </Flex>
                              )}
                            </Flex>
                          )}

                        </Flex>
                      )}

                      {lesson.position < module.lessons.length - 1 && (
                        <Box py="2" width="300px">
                          <Separator size="4" orientation="horizontal" />
                        </Box>
                      )}
                    </Flex>
                  ))}
                </Flex>

                {module.position < contentForm.modules.length - 1 && (
                  <Separator orientation="horizontal" size="4" my="4" />
                )}
              </Flex>
            ))}
          </Flex>

          <Flex justify="end" align="center" gap="2" px="4" py="2" className="bg-white border-t border-t-gray-200 fixed bottom-0 left-0 right-0 z-30">
              <Button type="button" variant="soft" onClick={discardChanges} disabled={isProcessing()}>
                Discard
              </Button>
              {courseId !== 'new' && courseData?.data.course.status === courseStatus.template && (
                <Button type="button" variant="soft" color="purple" disabled={isProcessing()} onClick={saveChanges}>
                  Update template
                </Button>
              )}

              <AlertDialog.Root>
                <AlertDialog.Trigger>
                  <Button type="button" variant="solid" color="purple" disabled={isProcessing()}>
                    {primaryActionLabel()}
                  </Button>
                </AlertDialog.Trigger>
                <AlertDialog.Content maxWidth="450px">
                  <AlertDialog.Title>
                    {primaryActionLabel()} course
                  </AlertDialog.Title>
                  <AlertDialog.Description size="2">
                    Are you sure? You won't be able to make changes until it's processed
                  </AlertDialog.Description>

                  <Flex gap="3" mt="4" justify="end">
                    <AlertDialog.Cancel>
                      <Button variant="soft" color="gray">
                        Cancel
                      </Button>
                    </AlertDialog.Cancel>
                    <AlertDialog.Action>
                      <Button type="button" variant="solid" color="purple" onClick={regenerate}>
                        {primaryActionLabel()}
                      </Button>
                    </AlertDialog.Action>
                  </Flex>
                </AlertDialog.Content>
              </AlertDialog.Root>
            </Flex>
        </Flex>
      )}
    </>
  );
}

export {
  CourseContent
}