import { fetchJSON } from "./utils";

async function loginAdmin({ email, password }) {  
  const path = '/user/login';
  const body = { email, password };
  const res = await fetchJSON(path, 'POST', { body: JSON.stringify(body) });
  return res.data;
}

export {
  loginAdmin,
}
